import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from '../Home/Modal_started';
import { Link } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";


const Article_upgrading_your_pos_syst_is_key_to_key_to_the_success_of_your_rest_cr = () => {

    useDocumentTitle(
        "¿Por qué modernizar tu sistema POS es clave para el éxito de tu restaurante en Costa Rica? | Invu POS"  
    ); 

  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const prefix = useLocalePrefix();
    
  return (
    <>

    <Helmet>
        <meta
            name="description"
            content="Costa Rica es un país con una oferta gastronómica cada vez más diversa y competitiva. Desde sodas típicas hasta restaurantes gourmet, el sector de alimentos y bebidas está creciendo rápidamente. Sin embargo, muchos negocios siguen operando con sistemas POS obsoletos que afectan la eficiencia, reducen las ventas y dificultan el cumplimiento de las normativas de facturación electrónica."
        />
        <meta name="keywords" content="¿Por qué modernizar tu sistema POS es clave para el éxito de tu restaurante en Costa Rica?" />
    </Helmet>
    {isOpen && <Modal_started setIsOpen={setIsOpen} />}
    <section class="w-full px-4 py-24 mx-auto max-w-7xl md:w-4/5">
    <div class="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
      <div class="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-3">
        <h1 class="mb-10 text-5xl font-medium text-gray-900 md:text-6xl max-w-4xl" itemprop="headline">
        ¿Por qué modernizar tu sistema POS es clave para el éxito de tu restaurante en Costa Rica?
        </h1>
      </div>
    </div>
    <div className="max-w-screen-xl mx-auto mt-4">
        <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-16 gap-x-10 md:gap-x-16">
          <div class="order-none lg:order-2 md:order-none">
          <p className="text-xl text-gray-900 font-light leading-relaxed mb-12">
          Costa Rica es un país con una oferta gastronómica cada vez más diversa y competitiva. Desde sodas típicas hasta restaurantes gourmet, el sector de alimentos y bebidas está creciendo rápidamente. Sin embargo, muchos negocios siguen operando con sistemas POS obsoletos que afectan la eficiencia, reducen las ventas y dificultan el cumplimiento de las normativas de facturación electrónica.
          </p>
        <div className="flex items-center space-x-2 mt-4 text-gray-500 uppercase text-xs font-semibold">
          <span>By <span className="text-black">Andrés Amaya</span></span>
          <span>&#8212;</span>
          <span>APRIL 10, 2025</span>
          <span>&#8212;</span>
          <span className="text-black">4 MIN READ</span>
        </div>
        <div className="flex space-x-2 mt-4">
          <span className="bg-blue-100 text-black text-xs font-semibold px-3 py-1 rounded">GESTIÓN</span>
          <span className="bg-gray-200 text-black text-xs font-semibold px-3 py-1 rounded">ARTÍCULO</span>
        </div>
        {/* Social Icons */}
        <div className="flex space-x-4 mt-6">
        <div class="flex items-center">
                <div class="flex space-x-6 text-gray-500">
                <a
                  class="hover:opacity-75"
                  href="https://www.instagram.com/invupos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Instagram </span>

                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  class="hover:opacity-75"
                  href="https://twitter.com/invupos?lang=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Twitter </span>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>

                <a
                  class="hover:opacity-75"
                  href="https://es-la.facebook.com/invuposofficial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Facebook </span>

                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
              </div>
        </div>
            </div>
            <div className="relative lg:m-auto group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-2xl"
                src="https://img.invupos.com/webinvu/Blog%20invu/por-que-modernizar-tu-sistema-pos-es-la-clave-para-el-exito-de-tu-restaurante-en-costa-rica.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>

        <hr class="my-16 h-0.5 border-t-0 bg-neutral-100" />

        <div className="bg-white flex flex-col md:flex-row justify-center items-start">
      {/* Sidebar - Table of Contents */}
      <div className="w-full md:w-1/3 md:pr-10 mb-10 md:mb-0">
        <div className="md:hidden">
          <button
            className="w-full flex items-center justify-between text-md font-semibold p-3 py-4 border-t border-b"
            onClick={() => setIsOpenOptions(!isOpenOptions)}
          >
             <span className="flex">Table of contents</span>
             <span className="ml-auto transition-transform duration-300 transform" style={{ transform: isOpenOptions ? 'rotate(180deg)' : 'rotate(0deg)' }}>
              {isOpenOptions ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M11.293 7.293a1 1 0 0 1 1.32 -.083l.094 .083l6 6l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059l-.002 .059l-.005 .058l-.009 .06l-.01 .052l-.032 .108l-.027 .067l-.07 .132l-.065 .09l-.073 .081l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002h-12c-.852 0 -1.297 -.986 -.783 -1.623l.076 -.084l6 -6z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" />
                </svg>
              )}
            </span>
          </button>
          {isOpenOptions && (
            <ul className="text-gray-700 space-y-2 text-md bg-gray-100 p-4 mt-6 rounded">
              <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/punto-de-venta" className="hover:text-indigo-500 transition-colors duration-300">
                    Punto de venta
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/comercio-electronico" className="hover:text-indigo-500 transition-colors duration-300">
                    E-commerce
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/programa-de-lealtad" className="hover:text-indigo-500 transition-colors duration-300">
                    Lealtad
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/plus" className="hover:text-indigo-500 transition-colors duration-300">
                    Plus
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/kds" className="hover:text-indigo-500 transition-colors duration-300">
                    KDS
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/quioscos" className="hover:text-indigo-500 transition-colors duration-300">
                    Autoservicio
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/marketing" className="hover:text-indigo-500 transition-colors duration-300">
                    Mercadeo
                </Link>
                </li>
            </ul>
          )}
        </div>
        <div className="hidden md:block">
        <h2 className="text-xl font-semibold mb-4">Nuestros Productos</h2>
            <ul className="text-gray-700 space-y-2 text-lg">
            <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/punto-de-venta" className="hover:text-indigo-500 transition-colors duration-300">
                    Punto de venta
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/comercio-electronico" className="hover:text-indigo-500 transition-colors duration-300">
                    E-commerce
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/programa-de-lealtad" className="hover:text-indigo-500 transition-colors duration-300">
                    Lealtad
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/plus" className="hover:text-indigo-500 transition-colors duration-300">
                    Plus
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/kds" className="hover:text-indigo-500 transition-colors duration-300">
                    KDS
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/quioscos" className="hover:text-indigo-500 transition-colors duration-300">
                    Autoservicio
                </Link>
                </li>
                <li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/productos/marketing" className="hover:text-indigo-500 transition-colors duration-300">
                    Mercadeo
                </Link>
                </li>
          </ul>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="w-full md:w-2/3 text-left">

        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        En este artículo te contamos por qué modernizar tu sistema de punto de venta (POS) es vital para el éxito de tu restaurante en Costa Rica y cómo Invu POS se presenta como la solución ideal para los negocios que quieren crecer con tecnología de verdad.
        </p>
       
        <h2 id="section1" className="text-4xl font-bold mb-4">
        La realidad del sector gastronómico en Costa Rica
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        El cliente costarricense es cada vez más exigente: espera rapidez, buena atención, opciones
        de pago modernas y transparencia en los procesos. Un sistema POS antiguo o limitado
        simplemente no está a la altura de estas demandas. Los restaurantes que no se adaptan a las
        nuevas tecnologías están perdiendo ventas y quedando rezagados frente a la competencia.
        </p>

        <h2 id="section1" className="text-4xl font-bold mt-12">
        5 razones para modernizar tu POS en Costa Rica
        </h2>
        <h3 className='font-semibold text-2xl mt-6'>1. Cumplimiento con la facturación electrónica:</h3>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        El cliente costarricense es cada vez más exigente: espera rapidez, buena atención, opciones
        de pago modernas y transparencia en los procesos. Un sistema POS antiguo o limitado
        simplemente no está a la altura de estas demandas. Los restaurantes que no se adaptan a las
        nuevas tecnologías están perdiendo ventas y quedando rezagados frente a la competencia.
        </p>

        <h3 className='font-semibold text-2xl mt-6'>2. Mayor eficiencia en el servicio</h3>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        Un POS moderno permite que los meseros tomen pedidos desde tablets o celulares,
        enviéndolos directamente a cocina. Esto reduce tiempos de espera, evita errores en las
        comandas y mejora la experiencia del cliente.
        </p>
       
        <h3 className='font-semibold text-2xl mt-6'>3. Control de inventario en tiempo real</h3>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        Uno de los dolores de cabeza de muchos restaurantes en Costa Rica es el manejo del
        inventario. Invu POS ofrece un sistema de gestión que permite saber en todo momento qué
        productos hay en bodega, cuánto se está vendiendo y cuándo hay que reabastecer.
        </p>

        <h3 className='font-semibold text-2xl mt-6'>4. Integración con delivery y pagos digitales</h3>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        El consumidor tico ya espera poder pedir desde apps como Uber Eats o Glovo y pagar con
        SINPE móvil o tarjetas. Invu POS se integra con plataformas de delivery y acepta pagos
        digitales, haciendo tu restaurante mucho más competitivo.
        </p>

        <h3 className='font-semibold text-2xl mt-6'>5. Informes claros para tomar mejores decisiones</h3>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        Con Invu POS puedes acceder a reportes automáticos de ventas, productos más vendidos,
        horarios pico y mucho más. Esto te permite planificar mejor, reducir pérdidas y aumentar tu
        rentabilidad.
        </p>

      </div>

    </div>


   
    <div className="bg-white flex flex-col md:flex-row justify-center items-start mt-14">
      {/* Sidebar - Table of Contents */}
      <div className="w-full md:w-1/3 md:pr-10 mb-10 md:mb-0">
      <div className="bg-white flex flex-col items-start text-left p-6 max-w-md mx-auto">
      {/* Heading */}
      <h2 className="text-3xl font-medium leading-tight mb-4">
      Obtén más información sobre Invu POS
      </h2>
      
      {/* Image */}
      <div className="w-full rounded-lg overflow-hidden mb-6">
        <img
          src="https://images.pexels.com/photos/7682340/pexels-photo-7682340.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="Business Tools"
          className="w-full h-auto object-cover"
        />
      </div>
      
      {/* Button */}
      <button
        className="px-6 py-3 border border-blue-500 text-blue-500 font-medium rounded-lg hover:bg-blue-100 transition duration-300"
        onClick={() => {
            setIsOpen(true);
        }}
      >
       Empieza Hoy ↗
      </button>
    </div>
      </div>
      
      {/* Main Content */}
      <div className="w-full md:w-2/3 text-left">
       

      <h2 id="section1" className="text-4xl font-bold mb-4 mt-10">
      Invu POS: la solución ideal para restaurantes en Costa
      Rica
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mt-4 mb-6">
        Invu POS es mucho más que un sistema de cobro: es una plataforma completa pensada para
        restaurantes en crecimiento. Está 100% adaptado al contexto costarricense, cumple con
        todos los requerimientos de Hacienda y se integra con herramientas que impulsan tu
        negocio.
        </p>
        <h3 className='font-semibold text-2xl'>Ventajas clave de Invu POS para el mercado tico:</h3>
        <ul class="list-disc ml-3 mt-6">
            <li class="text-gray-900 text-lg">Sistema homologado para facturación electrónica en Costa Rica.</li>
            <li class="text-gray-900 text-lg">Soporte local en español y capacitaciones incluidas.</li>
            <li class="text-gray-900 text-lg">Panel de control intuitivo y accesible desde cualquier dispositivo.</li>
            <li class="text-gray-900 text-lg">Integraciones con apps de delivery y pasarelas de pago como SINPE.</li>
            <li class="text-gray-900 text-lg">Gestión de mesas, cocina, inventario y promociones en un solo lugar.</li>
            <li class="text-gray-900 text-lg">Disponible en varios idiomas, ideal para negocios turísticos.</li>
            <li class="text-gray-900 text-lg">Compatible con distintos sistemas operativos (Windows, Android, iOS, Linux).</li>
            <li class="text-gray-900 text-lg">Totalmente adaptable a las necesidades de cada restaurante, desde pequeños cafés hasta cadenas de comida rápida.</li>
        </ul>
      </div>

    </div>

  </section>

    </>
  )
}

export default Article_upgrading_your_pos_syst_is_key_to_key_to_the_success_of_your_rest_cr;